import { useCommonStore } from '../store/search/common';
import { useUniversityStore } from '../store/search/university';
import { useInstituteStore } from '../store/search/institute';
import { useCountriesStore } from '../store/countries';

export function useCheckCategory() {
    const { t } = useI18n();
    const commonStore = useCommonStore();
    const instituteStore = useInstituteStore();
    const universityStore = useUniversityStore();
    const countriesStore = useCountriesStore();

    const { category } = storeToRefs(commonStore);

    const { destinationsUniversity, destinationsEnglish } = storeToRefs(countriesStore);

    const {
        universities,
        universityCourses,
        universitiesTotal,
        universityCoursesTotal,
        universitiesLoading,
        universitiesCurrentPage,
        universitiesLastPage,
        universityCoursesLoading,
        universityCoursesCurrentPage,
        universityCoursesLastPage,
        universitySearchKeyword,
        universityFilterCountries,
        universityFilterUniversities,
        universityFilterSubjects,
        universityFilterLevels,
        universityFilterIntakeDates,
        allUniversities,
        universityFilterPriceRange,
        universityFilterAppFees,
        universityFilterCities,
    } = storeToRefs(universityStore);
    const {
        institutes,
        instituteCourses,
        institutesTotal,
        instituteCoursesTotal,
        institutesLoading,
        institutesCurrentPage,
        institutesLastPage,
        instituteCoursesLoading,
        instituteCoursesCurrentPage,
        instituteFilterSubjects,
        instituteCoursesLastPage,
        instituteSearchKeyword,
        instituteFilterCountries,
        instituteFilterInstitutes,
        instituteFilterLevels,
        instituteFilterIntakeDates,
        allInstitutes,
        instituteFilterPriceRange,
        instituteFilterAppFees,
        instituteFilterCities,
    } = storeToRefs(instituteStore);

    // Computed Variables
    const sectionTitle = computed(
        () => t(`${category.value === 'university' ? 'Universities' : 'advSearch.englishschools'}`) || ''
    );

    const categoryDestinations = computed(() =>
        category.value === 'university' ? destinationsUniversity.value : destinationsEnglish.value || []
    );

    const categoryList = computed(() =>
        category.value === 'university' ? universities.value : institutes.value || []
    );

    const categoryCourses = computed(() =>
        category.value === 'university' ? universityCourses.value : instituteCourses.value || []
    );

    const categoryListTotal = computed(() =>
        category.value === 'university' ? universitiesTotal.value : institutesTotal.value || 0
    );

    const categoryCoursesTotal = computed(() =>
        category.value === 'university' ? universityCoursesTotal.value : instituteCoursesTotal.value || 0
    );

    const categoriesAndCoursesTotal = computed(() => categoryListTotal.value + categoryCoursesTotal.value);

    const categoryLoading = computed(() =>
        category.value === 'university' ? universitiesLoading.value : institutesLoading.value
    );
    const CourseLoading = computed(() =>
        category.value === 'university' ? universityCoursesLoading.value : instituteCoursesLoading.value
    );
    const categoryCurrentPage = computed(() =>
        category.value === 'university' ? universitiesCurrentPage.value : institutesCurrentPage.value
    );
    const CourseCurrentPage = computed(() =>
        category.value === 'university' ? universityCoursesCurrentPage.value : instituteCoursesCurrentPage.value
    );
    const categoryLastPage = computed(() =>
        category.value === 'university' ? universitiesLastPage.value : institutesLastPage.value
    );
    const courseLastPage = computed(() =>
        category.value === 'university' ? universityCoursesLastPage.value : instituteCoursesLastPage.value
    );
    const getCategoryList = computed(() =>
        category.value === 'university' ? universityStore.getUniversities : instituteStore.getInstitutes
    );
    const getCategoryCourses = computed(() =>
        category.value === 'university' ? universityStore.getUniversityCourses : instituteStore.getInstitutesCourses
    );
    const categorySearchKeyword = computed(() =>
        category.value === 'university' ? universitySearchKeyword : instituteSearchKeyword
    );
    const categoryFilterCountries = computed(() =>
        category.value === 'university' ? universityFilterCountries.value : instituteFilterCountries.value
    );

    const categoryFilterUniversitiesOrInstitutes = computed(() =>
        category.value === 'university' ? universityFilterUniversities.value : instituteFilterInstitutes.value
    );

    const categoryFilterSubjects = computed(() =>
        category.value === 'university' ? universityFilterSubjects.value : instituteFilterSubjects.value
    );

    const categoryFilterLevels = computed(() =>
        category.value === 'university' ? universityFilterLevels.value : instituteFilterLevels.value
    );

    const categoryFilterIntakeDates = computed(() =>
        category.value === 'university' ? universityFilterIntakeDates.value : instituteFilterIntakeDates.value
    );
    const categoryFilterClear = computed(() =>
        category.value === 'university' ? universityStore.universityClearFilter : instituteStore.instituteClearFilter
    );
    const getAllCategory = computed(() =>
        category.value === 'university' ? universityStore.getAllUniversities : instituteStore.getAllInstitutes
    );
    const courseFeesObj = computed(() =>
        category.value === 'university' ? universityStore.universityCoursesFees : instituteStore.instituteCoursesFees
    );
    const categoryFilterPriceRange = computed(() =>
        category.value === 'university' ? universityFilterPriceRange.value : instituteFilterPriceRange.value
    );
    const categoryFilterAppFees = computed(() =>
        category.value === 'university' ? universityFilterAppFees.value : instituteFilterAppFees.value
    );
    const categoryFilterCities = computed(() =>
        category.value === 'university' ? universityFilterCities.value : instituteFilterCities.value
    );
    const allCategory = computed(() => (category.value === 'university' ? allUniversities.value : allInstitutes.value));
    const totalFilters = computed(() => {
        let total = 0;
        if (categoryFilterCountries.value.length > 0) total += 1;
        if (categoryFilterUniversitiesOrInstitutes.value.length > 0) total += 1;
        if (categoryFilterSubjects.value.length > 0) total += 1;
        if (categoryFilterLevels.value.length > 0) total += 1;
        if (categoryFilterIntakeDates.value.length > 0) total += 1;
        if (categoryFilterPriceRange.value[0]?.priceRange?.length > 0) total += 1;
        if (categoryFilterAppFees.value.length > 0) total += 1;
        if (categoryFilterCities.value.length > 0) total += 1;
        return total;
    });
    const allFilterValues = computed(() => {
        return [
            ...categoryFilterCountries.value,
            ...categoryFilterUniversitiesOrInstitutes.value,
            ...categoryFilterSubjects.value,
            ...categoryFilterLevels.value,
            ...categoryFilterIntakeDates.value,
            ...categoryFilterPriceRange.value,
            ...categoryFilterAppFees.value,
            ...categoryFilterCities.value,
        ];
    });

    // reset filters categorySearchKeyword
    const resetCategorySearchKeyword = () => {
        universityStore.resetUniversitySearchKeyword();
        instituteStore.resetInstituteSearchKeyword();
    };

    return {
        sectionTitle,
        categoryList,
        categoryCourses,
        categoryListTotal,
        categoryCoursesTotal,
        categoriesAndCoursesTotal,
        categoryLoading,
        categoryCurrentPage,
        categoryLastPage,
        getCategoryList,
        CourseLoading,
        CourseCurrentPage,
        courseLastPage,
        getCategoryCourses,
        categorySearchKeyword,
        categoryFilterCountries,
        categoryFilterUniversitiesOrInstitutes,
        categoryFilterSubjects,
        categoryFilterLevels,
        categoryFilterIntakeDates,
        categoryFilterClear,
        totalFilters,
        allFilterValues,
        getAllCategory,
        allCategory,
        courseFeesObj,
        categoryFilterPriceRange,
        categoryFilterAppFees,
        categoryFilterCities,
        categoryDestinations,
        resetCategorySearchKeyword,
    };
}
